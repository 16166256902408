// utils
import React from "react"

import { ContentWrapper } from "./style"
import { PageElement } from "./index"
import { P0 } from "@styles"
import { mapTitle } from "./utils"

const Content = drugs => {
  return (
    <ContentWrapper>
      <P0 as="h1">{drugs.label}</P0>
      {drugs.indication?.value && (
        <PageElement
          idx={mapTitle[0].id}
          title={mapTitle[0]}
          value={drugs.indication.value}
        />
      )}
      {drugs.active_substance && (
        <PageElement
          idx={mapTitle[1].id}
          title={mapTitle[1]}
          value={[
            {
              type: "quote",
              value: drugs.active_substance,
            },
          ]}
        />
      )}
      {drugs.price?.reimbursement_rate && (
        <PageElement
          idx={mapTitle[2].id}
          title={mapTitle[2]}
          value={[
            {
              type: "price",
              value: drugs.price,
            },
          ]}
        />
      )}
      {drugs.administration?.value && (
        <PageElement
          idx={mapTitle[3].id}
          title={mapTitle[3]}
          value={drugs.administration.value}
        />
      )}
      {drugs.adverse_effect?.value && (
        <PageElement
          idx={mapTitle[4].id}
          title={mapTitle[4]}
          value={drugs.adverse_effect.value}
        />
      )}
      {drugs.adverse_effect_tags?.length > 0 && (
        <PageElement
          idx={mapTitle[5].id}
          title={mapTitle[5]}
          value={[
            {
              type: "tag",
              value: drugs.adverse_effect_tags,
            },
          ]}
        />
      )}
    </ContentWrapper>
  )
}

export default Content
