import React from "react"
import { Text } from "./index"

const List = ({ value, idx }) => {
  return (
    <React.Fragment key={`list-${idx}`}>
      <h3 style={{ margin: "8px 0" }}>{value?.title}</h3>
      <ul>
        {value.value.map((value, idx) => {
          switch (value.type) {
            case "text":
              return <Text key={`txt-${idx}`} idx={idx} value={value} />
            case "list":
              return (
                <React.Fragment key={`section-${idx}`}>
                  <h3 style={{ margin: "8px 0" }}>{value?.title}</h3>
                  <ul>
                    {value.value.map((item, idx) => {
                      typeof item.value instanceof Array ? (
                        item.value.map((item, idx) => {
                          return <li key={`key-${idx}`}>{item.value}</li>
                        })
                      ) : (
                        <li key={`key-${idx}`}>{item.value}</li>
                      )
                    })}
                  </ul>
                </React.Fragment>
              )
          }
        })}
      </ul>
    </React.Fragment>
  )
}

export default List
