import React from "react"
import { Block } from "./style"
import { P3, ButtonBlue } from "@styles"
import { StaticImage } from "gatsby-plugin-image"
import appStoreLogo from "../../images/appstore.svg"
import { AppLink } from "@components/CustomLink"

import googlePlayLogo from "../../images/google-play.svg"

const Stores = ({ title, pro }) => {
  return (
    <Block>
      <div>
        <StaticImage
          src="../images/doodle.png"
          width={170}
          style={{ marginTop: "-100px" }}
          alt="doodle"
        />

        <div style={{ marginTop: 16 }}>
          <P3 stolz mb={1} mbmd={4} weight="700">
            {title}
          </P3>
        </div>
        {pro ? (
          <div>
            <AppLink
              link={{
                target: "_blank",
                link_type: "web",
                url: "https://dub.sh/prodregister",
                // url: "https://dub.sh/zy9YCfQ",
              }}
              className="btn"
            >
              <ButtonBlue>
                <span>Cliquez ici</span>
              </ButtonBlue>
            </AppLink>
          </div>
        ) : (
          <div>
            <a
              href="https://app.adjust.com/y0h912n?campaign=Goodmed-Website&adgroup=Website&creative=Fiches-Médicaments"
              target="_blank"
              rel="nofollow noreferrer"
            >
              <img src={appStoreLogo} alt="appstore" />
            </a>
            <a
              href="https://app.adjust.com/y0h912n?campaign=Goodmed-Website&adgroup=Website&creative=Fiches-Médicaments"
              target="_blank"
              rel="nofollow noreferrer"
            >
              <img src={googlePlayLogo} alt="google play" />
            </a>
          </div>
        )}
      </div>
    </Block>
  )
}

export default Stores
