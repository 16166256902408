import React from "react"
import { NavLink, StickyList } from "./style"
import StickyBox from "react-sticky-box"
import Stores from "./Stores"

const Sidebar = ({ links }) => {
  return (
    <StickyBox offsetTop={160}>
      <StickyList>
        {links.map((link, idx) => {
          return (
            <NavLink
              to={`${link.id}`}
              activeClass="active"
              spy={true}
              offset={-95}
              key={idx}
            >
              {link.content}
            </NavLink>
          )
        })}
      </StickyList>
      <Stores title="Téléchargez l’app Goodmed" pro={false} />
      <Stores title="Vous êtes un professionel de santé ?" pro={true} />
    </StickyBox>
  )
}

export default Sidebar
