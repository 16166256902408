import React from "react"
import { Text, List, Section } from "./index"
import { Tag } from "./style"

export const mapTitle = {
  0: {
    id: "0",
    content: "À quoi sert ce médicament ?",
  },
  1: {
    id: "1",
    content: "Que contient ce médicament ?",
  },
  2: {
    id: "2",
    content: "Quel est son taux de remboursement ?",
  },
  3: { id: "3", content: "Comment le prendre ?" },
  4: {
    id: "4",
    content: "Quels sont ses effets indésirables possibles ?",
  },
  5: {
    id: "5",
    content: "Liste des effets indésirables possibles : ",
  },
}

export const renderComponent = value => {
  return (
    <>
      {value.map((value, idx) => {
        switch (value.type) {
          case "text":
            return <Text key={`text-${idx}`} idx={idx} value={value} />
          case "list":
            return <List key={`list-${idx}`} idx={idx} value={value} />
          case "section":
            return <Section key={`sec-${idx}`} idx={idx} value={value} />
          case "tag":
            return (
              <p key={`tag-${idx}`}>
                {value.value.map((tag, idx) => (
                  <Tag key={`tag-${idx}`}>{tag}</Tag>
                ))}
              </p>
            )
          case "price":
            return (
              <React.Fragment key={`price-${idx}`}>
                <p>Taux de remboursement: {value.value.reimbursement_rate}%</p>
                {value.value.reimbursement_rate && (
                  <p>Prix: {value.value.price}€</p>
                )}
              </React.Fragment>
            )
          case "quote":
            return <p key={`quote-${idx}`}>{value.value[0]}</p>
          default:
            break
        }
      })}
    </>
  )
}
