import styled from "styled-components"
import { Link } from "react-scroll"
import { variables, mediaQueries, T3 } from "@styles"

const NavLink = styled(Link)`
  display: block;
  margin-bottom: 1rem;
  position: relative;

  &.active {
    color: ${variables.darkOrange};
    transition: all 0.2s ease-in-out;
    &::before {
      content: "";
      background-color: ${variables.darkOrange};
      width: 5px;
      position: absolute;
      left: -16px;
      top: 0;
      height: 100%;
      border-radius: 20px;
    }
  }

  &:hover {
    cursor: pointer;
    color: ${variables.darkOrange};
  }
`

const StickyList = styled.ul`
  position: relative;
  margin-bottom: 6rem;
  padding-left: 1rem;
  &::before {
    transition: all 0.2s ease-in-out;
    content: "   ";
    background-color: ${props => props.theme.colors.grey25};
    width: 5px;
    position: absolute;
    left: 0px;
    top: 0;
    height: 100%;
    border-radius: 20px;
  }

  @media all and (max-width: 1200px) {
    display: none;
  }
`

const Block = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 40px 1px rgba(0, 0, 0, 0.08);

  border-radius: 26px;
  padding: 24px 32px 35px;

  @media ${mediaQueries.preMedium} {
    margin: 0 20px 20px;
  }

  @media ${mediaQueries.medium} {
    padding: 20px 40px;
    border-radius: 26px;
    margin-bottom: 20px;
    display: flex;
  }

  @media ${mediaQueries.large} {
    display: flex;
  }

  svg {
    display: block;
    margin: -120px 0 24px;
    max-width: 108px;

    @media ${mediaQueries.medium} {
      margin: -120px 0 16px;
      max-width: 136px;
    }
  }
`

const ContentWrapper = styled.div`
  background-color: #fff;
  box-shadow: 0px -42px 30px rgba(0, 0, 0, 0.08);
  min-height: 600px;
  border-radius: 33px;
  padding: 48px 64px 64px 64px;
  margin-top: -90px;
  z-index: 10;

  p,
  ul {
    font-family: "inter", sans-serif;
    line-height: 1.1;
    margin-bottom: 8px;
  }

  ul {
    list-style: inside;
  }

  @media ${mediaQueries.preNavbar} {
    padding: 48px 16px 64px 16px;
  }
`

const Tag = styled.span`
  padding: 4px 8px;
  margin-right: 10px;
  display: inline-flex;
  border: 2px solid ${variables.yellow};
  background-color: ${variables.lightYellow};
  border-radius: 30px;
  margin-bottom: 16px;
  white-space: nowrap;
`

const DrugTitle = styled(T3)`
  color: ${variables.darkOrange};
  margin-top: 16px;
  margin-bottom: 16px;
`

export { NavLink, StickyList, Block, ContentWrapper, Tag, DrugTitle }
