import React from "react"
import { Text, List } from "./index"
import { renderComponent } from "./utils"

const Section = ({ value, idx }) => {
  const hasChildren = value && value.length
  return (
    <React.Fragment key={`section-${idx}`}>
      <h3 style={{ margin: "8px 0" }}>{value?.title}</h3>

      {value.value.map((value, idx) => {
        switch (value.type) {
          case "text":
            return <Text key={`txt-${idx}`} idx={idx} value={value} />
          case "list":
            return <List key={`list-${idx}`} idx={idx} value={value} />
          case "section":
            return (
              <React.Fragment key={`subsection-${idx}`}>
                <h3 style={{ margin: "8px 0" }}>{value?.title}</h3>
                {value.value.map((value, idx) => {
                  switch (value.type) {
                    case "text":
                      return <Text idx={idx} value={value} />
                    case "list":
                      return <List idx={idx} value={value} />
                    default:
                      break
                  }
                })}
                {hasChildren && renderComponent(value)}
              </React.Fragment>
            )
          default:
            break
        }
      })}
    </React.Fragment>
  )
}

export default Section
