import React from "react"
import { graphql } from "gatsby"

import { GridItem } from "@styles"
import DrugLayout from "../components/drug-layout"
import Seo from "../components/seo"

import { Sidebar, Content } from "./drug-components"
import { mapTitle } from "./drug-components/utils"

const DrugPage = ({ data, pageContext }) => {
  const navigation = data.prismicNavigation || {}
  const drugs = pageContext.drug
  const navigationLink = []

  if (drugs.label !== undefined) {
    drugs.indication.value && navigationLink.push(mapTitle[0])
    drugs.active_substanßce && navigationLink.push(mapTitle[1])
    drugs.price.reimbursement_rate && navigationLink.push(mapTitle[2])
    drugs.administration.value && navigationLink.push(mapTitle[3])
    drugs.adverse_effect && navigationLink.push(mapTitle[4])
    drugs.adverse_effect_tags?.length > 0 && navigationLink.push(mapTitle[5])
  }

  return (
    <DrugLayout navigation={navigation} title="Notice médicament">
      <Seo title={drugs.label} description={drugs.label} lang="fr" />
      <GridItem col={{ sm: 12, md: 4, lg: 4 }}>
        <Sidebar links={navigationLink} />
      </GridItem>
      <GridItem col={{ sm: 12, md: 8, lg: 8 }}>
        <Content {...drugs} />
      </GridItem>
    </DrugLayout>
  )
}

export default DrugPage

export const query = graphql`
  query ($lang: String) {
    prismicNavigation(lang: { eq: $lang }) {
      ...Navigation
    }
  }
`
