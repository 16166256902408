// utils
import React from "react"
import { Element } from "react-scroll"
import { renderComponent } from "./utils"

import { DrugTitle } from "./style"

const PageElement = ({ value, title, idx }) => {
  return (
    <Element name={title.id} key={idx}>
      <DrugTitle as="h2">{title.content}</DrugTitle>
      {renderComponent(value)}
    </Element>
  )
}

export default PageElement
